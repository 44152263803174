<template>
  <div>
    <section id="detail-kelas" class="py-5">
      <v-row v-show="loadContent" class="fill-height" align-content="center" justify="center" style="height:60vh">
        <v-col class="text-subtitle-1 text-center color-navy-soft" cols="12">
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
      <div v-show="content" class="col-md-10 mx-auto">
        <v-breadcrumbs large :items="items" class="pb-0 ps-4 pt-0"></v-breadcrumbs>
        <div class="row">
          <div class="col-md-12">
            <div>
              <v-card-text class="">
                <h4 class="headline">{{ this.detailClass.title }}</h4>
              </v-card-text>
            </div>
          </div>
          <div class="col-md-8 d-none d-md-block">
            <v-card elevation="0">
              <v-card-text class="pt-0 px-0 color-black">
                <div class="d-none d-md-block">
                  <img :src="this.information.url" style="width: 100%;object-fit: cover;">
                  <v-tabs id="" v-model="tabs">
                    <v-tab class="text-capitalize">Deskripsi</v-tab>
                    <v-tab class="text-capitalize" @click="filterRate = 0">Ulasan</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabs" class="setting col-md-12">
                    <v-tab-item>
                      <div class="">

                        
                        <vue-show-more-text :text="detailClass.program_benefits" :lines="10" :more-text="more"
                          :less-text="less" additional-content-css="font-size:0.875rem !important;"
                          additional-content-expanded-css="font-size:0.875rem !important;"
                          additional-anchor-css="font-size: 0.875rem !important;font-weight:bold;color:#f05326!important; text-align: center!important;"
                          @click="change" />
                        <h3 class="pa-2">Bidang Studi</h3>
                        <p class="pa-2 fs-fix">
                          {{ this.studies.title }}
                        </p>

                        <h3 class="pa-2">Lembaga Pengajar</h3>
                        <div class="row">
                          <div class="col-md-3 align-self-center mx-auto text-center">
                            <img src="@/assets/images/logo-yec-new.png" style="width: 50%;" />
                          </div>
                          <div class="col-md-9">
                            <p class="pa-2 fs-fix">
                              Yureka Education Center (YEC) berdiri pada tahun 2010 dengan nama awal Eureka Tour, sebuah
                              layanan jasa tour spesialis Kampung Inggris. Saat ini YEC fokus membantu setiap peserta
                              menjadi individu lebih baik. Tidak hanya sebatas menjadi jasa tour, namun juga menjadi
                              konsultan pendidikan dan media yang membantu menjadikan diri lebih baik dengan kemudahan
                              akses informasi dan program pelatihan terkait tes TOEFL, IELTS, Persiapan Kerja dan
                              Pembelajaran Bahasa Inggris Sehari-hari.
                            </p>
                          </div>
                        </div>


                        <h3 class="pa-2 mb-2">Fasilitator</h3>
                        <div class="row">
                          <div v-for="(item, index) in this.detailClass.fasilitator" :key="'A' + index">
                            <div class="col-md-12 ma-auto">
                              <img :src="item.picture.url" class="mx-2 text-center"
                                style="width: 120px;height: 120px;border-radius: 50%;object-fit: cover;">
                            </div>
                          </div>
                          <div class="col-md-12" v-for="(item, index) in this.fasilitator" :key="'B' + index">
                            <p class="pa-2 mb-0 fs-fix" v-html="item.description"></p>
                          </div>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div v-if="detailClass.total_participants > 0" class="pt-5">
                        <p class="mb-3 text-h5">Peserta Bergabung <b
                            style="color: #F05326;">{{ detailClass.total_participants }}</b></p>
                      </div>
                      <div class="pt-5">
                        <div v-if="dataReview.total === 0" class="mt-5 text-center col-md-12">
                          <img src="@/assets/images/empty-review.png" width="70" />
                          <p class="text-h6">Belum ada ulasan untuk kelas ini</p>
                        </div>
                        <div v-else>
                          <div class="mt-5 mb-3 text-center rate col-md-12">
                            <div class="row">
                              <v-card class="px-1 py-4 rounded-lg" outlined elevation="0">
                                <h2 class="font-weight-bold display-2">
                                  {{ dataReview.rating }}
                                </h2>
                                <v-rating v-if="dataReview.rating" v-model="dataReview.rating" color="#FFCC18"
                                  background-color="#E0E6ED" empty-icon="$ratingFull" half-increments hover dense
                                  size="40" readonly></v-rating>
                                <p class="mb-0 fs-14 color-disable">({{ toCurrency(dataReview.total) }} Ulasan)
                                </p>
                              </v-card>
                              <v-card class="pa-2 col-md-6 col-12" elevation="0">
                                <v-list-item-group>
                                  <v-list-item 
                                    @click="() => filterRate = 5"
                                    class="border-0" 
                                    style="
                                      min-height: auto !important;
                                      border: none !important;
                                    ">
                                    <v-list-item-icon class="mt-1 mb-0 mr-2">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>5</span>
                                    </v-list-item-icon>
  
                                    <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                      <v-progress-linear :value="persenRate5" color="amber" height="8"
                                        rounded></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{ dataReview.star?.five }}
                                      </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item 
                                    @click="() => filterRate = 4"
                                    class="border-0" 
                                    style="
                                      min-height: auto !important;
                                      border: none !important;
                                    ">
                                    <v-list-item-icon class="mt-1 mb-0 mr-2">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>4</span>
                                    </v-list-item-icon>
  
                                    <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                      <v-progress-linear :value="persenRate4" color="amber" height="8"
                                        rounded></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{ dataReview.star?.four }}
                                      </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item 
                                    @click="() => filterRate = 3"
                                    class="border-0" 
                                    style="
                                      min-height: auto !important;
                                      border: none !important;
                                    ">
                                    <v-list-item-icon class="mt-1 mb-0 mr-2">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>3</span>
                                    </v-list-item-icon>
  
                                    <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                      <v-progress-linear :value="persenRate3" color="amber" height="8"
                                        rounded></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{ dataReview.star?.three }}
                                      </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item 
                                    @click="() => filterRate = 2"
                                    class="border-0" 
                                    style="
                                      min-height: auto !important;
                                      border: none !important;
                                    ">
                                    <v-list-item-icon class="mt-1 mb-0 mr-2">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>2</span>
                                    </v-list-item-icon>
  
                                    <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                      <v-progress-linear :value="persenRate2" color="amber" height="8"
                                        rounded></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{ dataReview.star?.two }}
                                      </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item 
                                    @click="() => filterRate = 1"
                                    class="border-0" 
                                    style="
                                      min-height: auto !important;
                                      border: none !important;
                                    ">
                                    <v-list-item-icon class="mt-1 mb-0 mr-2">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>1</span>
                                    </v-list-item-icon>
  
                                    <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                      <v-progress-linear :value="persenRate1" color="amber" height="8"
                                        rounded></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{ dataReview.star?.one }}
                                      </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-card>
                            </div>
                          </div>
                          <div v-if="totalData === 0" class="mt-5 text-center col-md-12">
                            <img src="@/assets/images/empty-review.png" width="70">
                            <p class="text-h6">Tidak ada ulasan untuk rating ini</p>
                          </div>
                          <div v-else>
                            <v-row v-if="isLoadList" class="fill-height" align-content="center" justify="center" style="height:60vh">
                              <v-col class="text-center text-subtitle-1 color-navy-soft" cols="12">
                                Tunggu beberapa saat ...
                              </v-col>
                              <v-col cols="6">
                                <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                              </v-col>
                            </v-row>
                            <v-col v-else md="12">
                              <div class="d-flex justify-space-between">
                                <div>
                                  <p class="mb-0 text-h6">Apa Kata Mereka</p>
                                </div>
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      dark
                                      text
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ sort_type == 'desc' ? 'Terbaru' : 'Terlama' }}
                                      <v-icon right>mdi-sort</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item
                                      v-for="(sortType, index) in itemsSortReview"
                                      :key="index"
                                      @click="toggleSort(sortType.value)"
                                    >
                                      <v-list-item-title>{{ sortType.text }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                              <v-list three-line id="detail-kelas">
                                <template>
                                  <v-list-item v-for="(item, i) in listReview" :key="i">
                                    <v-list-item-avatar>
                                      <img src="@/assets/images/no-pict.png" />
                                    </v-list-item-avatar>
    
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <div class="d-flex justify-space-between">
                                          <div>
                                            <div class="mb-1">{{ item.member.fullname }}</div>
                                            <div class="fs-12 color-disable">{{ convertDateReview(item.created_at) }}</div>
                                          </div>
                                          <v-rating :value="item.star" small color="#FFCC18" background-color="#E0E6ED"
                                            dense></v-rating>
                                        </div>
                                      </v-list-item-title>
    
                                      <v-list-item-subtitle style="display: block !important">
                                        {{ item.review }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-list>
                            </v-col>
                            <v-col cols="12" class="justify-center d-flex pagination-review px-6">
                              <v-row
                                class="mt-2"
                                align="center"
                                justify="center">
                                <span class="primary--text">Items per page</span>
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      dark
                                      text
                                      color="primary"
                                      class="ml-2"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ itemsPerPage }}
                                      <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <v-list-item
                                      v-for="(limit, index) in itemsPerPageArray"
                                      :key="index"
                                      @click="changeItemsPerPageReview(limit)"
                                    >
                                      <v-list-item-title>{{ limit }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>

                                <v-spacer></v-spacer>

                                <span
                                  class="mr-4
                                  primary--text d-flex justify-center align-center"
                                >
                                  Page <v-select
                                  v-model="pageReview"
                                  dense
                                  class="pa-0 ma-0 mx-2 primary--text select-review"
                                  color="primary"
                                  elevation="0"
                                  hide-details
                                  :items="
                                    Array.from(
                                      {
                                        length: Math.ceil(totalData / itemsPerPage),
                                      },
                                      (_, i) => i + 1
                                    )
                                  "
                                  @change="getReviewClass(pageReview)"
                                  style="width: 6rem; text-align: center !important"
                                 /> of {{ toCurrency(totalPage) }}
                                </span>
                                <v-btn icon depressed color="primary" class="mr-1" small
                                  :disabled="pageReview === 1" @click="getReviewClass(pageReview - 1)">
                                  <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-btn icon depressed color="primary" class="ml-1" small
                                  :disabled="pageReview === totalPage" @click="getReviewClass(pageReview + 1)">
                                  <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                              </v-row>
                            </v-col>
                            <!-- <v-btn 
                              :loading="isLoad"
                              v-show="totalData > 20" color="primary" class="mx-auto text-center btn-beli" text
                              @click="getReviewClass(-1)">
                              Tampilkan lebih banyak
                            </v-btn> -->
                          </div>
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-12 pa-0 d-lg-none d-md-none d-sm-block" style="background: #fff;min-height: 80vh;">
            <img :src="this.information.url" style="width: 100%">
            <div class="button-beli pa-2 ps-5">
              <span v-show="program.discount_price != 0" class="fs-14 color-strike"><s>Rp {{
                toCurrency(program.discount_price)}}
                </s></span>
              <h2 class="color-state">Rp {{ toCurrency(program.selling_price) }}</h2>
            </div>

            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab class="text-capitalize">Deskripsi</v-tab>
              <v-tab class="text-capitalize">Ulasan</v-tab>
              <v-tab class="text-capitalize">Aktivitas</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="pa-2 ps-5">
              <v-tab-item>
                <v-card elevation="0" class="bg-transparent pt-5">
                  <div class="">
                    <vue-show-more-text :text="detailClass.program_benefits" :lines="10" :more-text="more"
                      :less-text="less" additional-content-css="font-size:0.875rem !important;"
                      additional-content-expanded-css="font-size:0.875rem !important;"
                      additional-anchor-css="font-size: 0.875rem !important;font-weight:bold;color:#f05326!important; text-align: center!important;"
                      @click="change" />
                    <h3 class="pa-2">Bidang Studi</h3>
                    <p class="pa-2 fs-fix">
                      {{ this.studies.title }}
                    </p>
                    <h3 class="pa-2">Lembaga Pengajar</h3>
                    <div class="row">
                      <div class="col-md-3 align-self-center mx-auto text-center">
                        <img src="@/assets/images/logo-yec-new.png" style="width: 50%;" />
                      </div>
                      <div class="col-md-9">
                        <p class="pa-2 fs-fix">
                          Yureka Education Center (YEC) berdiri pada tahun 2010 dengan nama awal Eureka Tour, sebuah
                          layanan jasa
                          tour spesialis Kampung Inggris. Saat ini YEC fokus membantu setiap peserta menjadi individu
                          lebih baik.
                          Tidak hanya sebatas menjadi jasa tour, namun juga menjadi konsultan pendidikan dan media yang
                          membantu
                          menjadikan diri lebih baik dengan kemudahan akses informasi dan program pelatihan terkait tes
                          TOEFL,
                          IELTS, Persiapan Kerja dan Pembelajaran Bahasa Inggris Sehari-hari.
                        </p>
                      </div>
                    </div>


                    <h3 class="pa-2 mb-2">Fasilitator</h3>
                    <div class="row">
                      <div v-for="(item, index) in this.detailClass.fasilitator" :key="'A' + index">
                        <div class="col-md-12 ma-auto">
                          <img :src="item.picture.url" class="mx-2 text-center"
                            style="width: 120px;height: 120px;border-radius: 50%;object-fit: cover;">
                        </div>
                      </div>
                      <div class="col-md-12" v-for="(item, index) in this.fasilitator" :key="'B' + index">
                        <p class="pa-2 mb-0 fs-fix" v-html="item.description"></p>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <div v-if="detailClass.total_participants > 0" class="pt-5">
                        <p class="mb-3 text-h5">Peserta Bergabung <b
                            style="color: #F05326;">{{ detailClass.total_participants }}</b></p>
                </div>
                <div class="pt-5">
                  <div v-if="dataReview.total === 0" class="mt-5 text-center col-md-12">
                    <img src="@/assets/images/empty-review.png" width="70" />
                    <p class="text-h6">Belum ada ulasan untuk kelas ini</p>
                  </div>
                  <div v-else>
                    <div class="mt-5 mb-3 text-center rate col-md-12">
                      <div class="row">
                        <v-card class="px-1 py-4 rounded-lg" outlined elevation="0">
                          <h2 class="font-weight-bold display-2">
                            {{ dataReview.rating }}
                          </h2>
                          <v-rating v-if="dataReview.rating" v-model="dataReview.rating" color="#FFCC18"
                            background-color="#E0E6ED" empty-icon="$ratingFull" half-increments hover dense
                            size="40" readonly></v-rating>
                          <p class="mb-0 fs-14 color-disable">({{ dataReview.total }} Ulasan)
                          </p>
                        </v-card>
                        <v-card class="pa-2 col-md-6 col-12" elevation="0">
                          <v-list-item-group>
                            <v-list-item 
                              @click="() => filterRate = 5"
                              class="border-0" 
                              style="
                                min-height: auto !important;
                                border: none !important;
                              ">
                              <v-list-item-icon class="mt-1 mb-0 mr-2">
                                <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>5</span>
                              </v-list-item-icon>

                              <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                <v-progress-linear :value="persenRate5" color="amber" height="8"
                                  rounded></v-progress-linear>
                              </v-list-item-content>
                              <v-list-item-action class="my-0">
                                <span class="color-disable">{{ dataReview.star?.five }}
                                </span>
                              </v-list-item-action>
                            </v-list-item>
                            <v-list-item 
                              @click="() => filterRate = 4"
                              class="border-0" 
                              style="
                                min-height: auto !important;
                                border: none !important;
                              ">
                              <v-list-item-icon class="mt-1 mb-0 mr-2">
                                <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>4</span>
                              </v-list-item-icon>

                              <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                <v-progress-linear :value="persenRate4" color="amber" height="8"
                                  rounded></v-progress-linear>
                              </v-list-item-content>
                              <v-list-item-action class="my-0">
                                <span class="color-disable">{{ dataReview.star?.four }}
                                </span>
                              </v-list-item-action>
                            </v-list-item>
                            <v-list-item 
                              @click="() => filterRate = 3"
                              class="border-0" 
                              style="
                                min-height: auto !important;
                                border: none !important;
                              ">
                              <v-list-item-icon class="mt-1 mb-0 mr-2">
                                <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>3</span>
                              </v-list-item-icon>

                              <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                <v-progress-linear :value="persenRate3" color="amber" height="8"
                                  rounded></v-progress-linear>
                              </v-list-item-content>
                              <v-list-item-action class="my-0">
                                <span class="color-disable">{{ dataReview.star?.three }}
                                </span>
                              </v-list-item-action>
                            </v-list-item>
                            <v-list-item 
                              @click="() => filterRate = 2"
                              class="border-0" 
                              style="
                                min-height: auto !important;
                                border: none !important;
                              ">
                              <v-list-item-icon class="mt-1 mb-0 mr-2">
                                <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>2</span>
                              </v-list-item-icon>

                              <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                <v-progress-linear :value="persenRate2" color="amber" height="8"
                                  rounded></v-progress-linear>
                              </v-list-item-content>
                              <v-list-item-action class="my-0">
                                <span class="color-disable">{{ dataReview.star?.two }}
                                </span>
                              </v-list-item-action>
                            </v-list-item>
                            <v-list-item 
                              @click="() => filterRate = 1"
                              class="border-0" 
                              style="
                                min-height: auto !important;
                                border: none !important;
                              ">
                              <v-list-item-icon class="mt-1 mb-0 mr-2">
                                <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>1</span>
                              </v-list-item-icon>

                              <v-list-item-content class="py-0" style="flex: none; width: 128px">
                                <v-progress-linear :value="persenRate1" color="amber" height="8"
                                  rounded></v-progress-linear>
                              </v-list-item-content>
                              <v-list-item-action class="my-0">
                                <span class="color-disable">{{ dataReview.star?.one }}
                                </span>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list-item-group>
                        </v-card>
                      </div>
                    </div>
                    <div v-if="totalData === 0" class="mt-5 text-center col-md-12">
                      <img src="@/assets/images/empty-review.png" width="70">
                      <p class="text-h6">Tidak ada ulasan untuk rating ini</p>
                    </div>
                    <div v-else>
                      <v-row v-if="isLoadList" class="fill-height" align-content="center" justify="center" style="height:60vh">
                        <v-col class="text-center text-subtitle-1 color-navy-soft" cols="12">
                          Tunggu beberapa saat ...
                        </v-col>
                        <v-col cols="6">
                          <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                        </v-col>
                      </v-row>
                      <v-col v-else md="12">
                        <div class="d-flex justify-space-between">
                          <div>
                            <p class="mb-0 text-h6">Apa Kata Mereka</p>
                          </div>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                dark
                                text
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ sort_type == 'desc' ? 'Terbaru' : 'Terlama' }}
                                <v-icon right>mdi-sort</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(sortType, index) in itemsSortReview"
                                :key="index"
                                @click="toggleSort(sortType.value)"
                              >
                                <v-list-item-title>{{ sortType.text }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <v-list three-line id="detail-kelas">
                          <template>
                            <v-list-item v-for="(item, i) in listReview" :key="i">
                              <v-list-item-avatar>
                                <img src="@/assets/images/no-pict.png" />
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>
                                  <div class="d-flex justify-space-between">
                                    <div>
                                      <div class="mb-1">{{ item.member.fullname }}</div>
                                      <div class="fs-12 color-disable">{{ convertDateReview(item.created_at) }}</div>
                                    </div>
                                    <v-rating :value="item.star" small color="#FFCC18" background-color="#E0E6ED"
                                      dense></v-rating>
                                  </div>
                                </v-list-item-title>

                                <v-list-item-subtitle style="display: block !important">
                                  {{ item.review }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-col>
                      <v-col cols="12" class="justify-center d-flex pagination-review px-6">
                        <v-row
                          class="mt-2"
                          align="center"
                          justify="center">
                          <span class="primary--text">Items per page</span>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                dark
                                text
                                color="primary"
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ itemsPerPage }}
                                <v-icon>mdi-chevron-down</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(limit, index) in itemsPerPageArray"
                                :key="index"
                                @click="changeItemsPerPageReview(limit)"
                              >
                                <v-list-item-title>{{ limit }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>

                          <v-spacer></v-spacer>

                          <span
                            class="mr-4
                            primary--text d-flex justify-center align-center"
                          >
                            Page <v-select
                            v-model="pageReview"
                            dense
                            class="pa-0 ma-0 mx-2 primary--text select-review"
                            color="primary"
                            elevation="0"
                            hide-details
                            :items="
                              Array.from(
                                {
                                  length: Math.ceil(totalData / itemsPerPage),
                                },
                                (_, i) => i + 1
                              )
                            "
                            @change="getReviewClass(pageReview)"
                            style="width: 6rem; text-align: center !important"
                            /> of {{ totalPage }}
                          </span>
                          <v-btn
                            icon
                            depressed
                            color="primary"
                            class="mr-1"
                            small
                            @click="getReviewClass(pageReview - 1)"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            depressed
                            color="primary"
                            class="ml-1"
                            small
                            @click="getReviewClass(pageReview + 1)"
                          >
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-row>
                      </v-col>
                      <!-- <v-btn 
                        :loading="isLoad"
                        v-show="totalData > 20" color="primary" class="mx-auto text-center btn-beli" text
                        @click="getReviewClass(-1)">
                        Tampilkan lebih banyak
                      </v-btn> -->
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-card elevation="0" class="bg-transparent">
                  <div>
                    <v-expansion-panels class="custom-panels overflow-auto" style="max-height: 64vh">
                      <v-expansion-panel class="rounded-0" v-for="(item, index) in this.detailClass.topic" :key="index">
                        <v-expansion-panel-header class="px-4">
                          <div>
                            <div class="mb-1 font-weight-bold">{{ item.name }}</div>
                            <div class="text-body-2 color-navy-soft">
                              {{ item.activities.length }} Aktivitas
                            </div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="navy-soft pa-0">
                          <v-list dense class="col-12 pa-0">
                            <v-list-item-group>
                              <v-divider></v-divider>
                              <!-- <v-list-item
                                      v-for="(i, key) in 5"
                                      :key="i"
                                      class="grey lighten-5 d-block"
                                      color="primary"
                                      @click="
                                        pageType = i;
                                        showActivity = false;
                                      "
                                    > -->
                              <v-list-item v-for="(act, i) in item.activities" :key="i" class="grey lighten-5 d-block"
                                color="primary">
                                <!-- pageType = act; -->
                                <v-list-item-content>
                                  <div class="d-flex my-1">
                                    <div style="width: 2rem">{{ i + 1 }}.</div>
                                    <div>
                                      <div class="
                                                mb-1
                                                font-weight-medium                                                
                                              ">
                                        {{ act.title }}
                                      </div>
                                      <div class="color-navy-soft text-body-2 d-flex">
                                        <template v-if="act.type == 'menonton_video'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-film-line</v-icon>
                                            Menonton Video
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            {{ dateTime(act.settings.estimated_duration) }}
                                          </span>
                                        </template>
                                        <template
                                          v-else-if="act.type == 'pre_test' || act.type == 'post_test' || act.type == 'quiz'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-survey-line</v-icon>
                                            Mengerjakan Soal
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            {{ dateTime(act.settings.estimated_duration) }}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'praktek_mandiri'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-file-download-line</v-icon>
                                            Praktek Mandiri
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            {{ dateTime(act.settings.estimated_duration) }}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-file-upload-line</v-icon>
                                            Evaluasi Praktek Mandiri
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            {{ dateTime(act.settings.estimated_duration) }}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'berdiskusi_daring'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-survey-line</v-icon>
                                            Berdiskusi Daring
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            {{ dateTime(act.settings.estimated_duration) }}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'evaluasi'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-edit-box-line</v-icon>
                                            Evaluasi
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            {{ dateTime(act.settings.estimated_duration) }}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'membaca-e-book'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-file-list-line</v-icon>
                                            Membaca E-book
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            {{ dateTime(act.settings.estimated_duration) }}
                                          </span>
                                        </template>
                                      </div>
                                    </div>
                                  </div>
                                </v-list-item-content>
                                <v-divider style="
                                          position: absolute;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "></v-divider>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <div class="button-beli pa-2">
                      <v-btn color="primary" rounded class="mx-auto text-center btn-beli" block elevation="2"
                        @click="dialog = true">
                        Beli Kelas Online
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <div class="col-md-4">
            <v-card class="d-none d-md-block">
              <v-card-text class="pb-0">
                <h2 class="mb-3 color-sale">Aktivitas</h2>
              </v-card-text>
              <div>
                <v-expansion-panels class="custom-panels overflow-auto" style="max-height: 64vh">
                  <v-expansion-panel class="rounded-0" v-for="(item, index) in this.detailClass.topic" :key="index">
                    <v-expansion-panel-header class="px-4">
                      <div>
                        <div class="mb-1 font-weight-bold">{{ item.name }}</div>
                        <div class="text-body-2 color-navy-soft">
                          {{ item.activities.length }} Aktivitas
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="navy-soft pa-0">
                      <v-list dense class="col-12 pa-0">
                        <v-list-item-group>
                          <v-divider></v-divider>
                          <v-list-item v-for="(act, i) in item.activities" :key="i" class="grey lighten-5 d-block"
                            color="primary">
                            <v-list-item-content>
                              <div class="d-flex my-1">
                                <div style="width: 2rem">{{ i + 1 }}.</div>
                                <div>
                                  <div class="
                                      mb-1
                                      font-weight-medium
                                    ">
                                    {{ act.title }}
                                  </div>
                                  <div class="color-navy-soft text-body-2 d-flex">
                                    <template v-if="act.type == 'menonton_video'">
                                      <span class="d-flex mr-2">
                                        <v-icon class="navy-soft--text mr-1" small>ri-film-line</v-icon>
                                        Menonton Video
                                      </span>
                                      <span class="d-flex">
                                        <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                        {{ dateTime(act.settings.estimated_duration) }}
                                      </span>
                                    </template>
                                    <template
                                      v-else-if="act.type == 'pre_test' || act.type == 'post_test' || act.type == 'quiz'">
                                      <span class="d-flex mr-2">
                                        <v-icon class="navy-soft--text mr-1" small>ri-survey-line</v-icon>
                                        Mengerjakan Soal
                                      </span>
                                      <span class="d-flex">
                                        <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                        {{ dateTime(act.settings.estimated_duration) }}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'praktek_mandiri'">
                                      <span class="d-flex mr-2">
                                        <v-icon class="navy-soft--text mr-1" small>ri-file-download-line</v-icon>
                                        Praktek Mandiri
                                      </span>
                                      <span class="d-flex">
                                        <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                        {{ dateTime(act.settings.estimated_duration) }}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
                                      <span class="d-flex mr-2">
                                        <v-icon class="navy-soft--text mr-1" small>ri-file-upload-line</v-icon>
                                        Evaluasi Praktek Mandiri
                                      </span>
                                      <span class="d-flex">
                                        <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                        {{ dateTime(act.settings.estimated_duration) }}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'berdiskusi_daring'">
                                      <span class="d-flex mr-2">
                                        <v-icon class="navy-soft--text mr-1" small>ri-survey-line</v-icon>
                                        Berdiskusi Daring
                                      </span>
                                      <span class="d-flex">
                                        <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                        {{ dateTime(act.settings.estimated_duration) }}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'evaluasi'">
                                      <span class="d-flex mr-2">
                                        <v-icon class="navy-soft--text mr-1" small>ri-edit-box-line</v-icon>
                                        Evaluasi
                                      </span>
                                      <span class="d-flex">
                                        <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                        {{ dateTime(act.settings.estimated_duration) }}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'membaca-e-book'">
                                      <span class="d-flex mr-2">
                                        <v-icon class="navy-soft--text mr-1" small>ri-file-list-line</v-icon>
                                        Membaca E-book
                                      </span>
                                      <span class="d-flex">
                                        <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                        {{ dateTime(act.settings.estimated_duration) }}
                                      </span>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </v-list-item-content>
                            <v-divider style="
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 0;
                              "></v-divider>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div class="button-beli pa-2">
                  <span v-show="program.discount_price != 0" class="fs-14 color-strike"><s>Rp {{
                    toCurrency(program.discount_price)}} </s></span>
                  <h2 class="color-state mb-3">Rp {{ toCurrency(program.selling_price) }}</h2>
                  <v-btn color="primary" rounded class="mx-auto text-center btn-beli" block elevation="2"
                    @click="dialog = true">
                    Beli Kelas Online
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </section>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title class="font-weight-bold">Pilih Platform Pembelian</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark icon @click="dialog = false" class="ml-auto" color="primary">
              <v-icon>ri-close-fill</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="pb-0 pt-2">
          <div class="col-md-8 text-center mx-auto py-3">
            <div v-for="(item, index) in program.selling_link" :key="'A' + index">
              <v-btn class="rounded-xl mb-2 white--text" color="primary" :href="item.url" target="_blank" block
                v-text="item.title"></v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vueShowMoreText from 'vue-show-more-text'
import moment from 'moment'

export default {
  name: "detail-class",
  components: {
    vueShowMoreText
  },
  data() {
    return {
      offset: true,
      a: 5,
      rate: {},
      loadContent: true,
      content: false,
      tabs: null,
      tab: null,
      test: 'asd√',
      items: [
        {
          text: 'Beranda',
          disabled: false,
          color: 'primary',
          href: '/',
        },
        {
          text: 'Kelas',
          disabled: true,
          href: '#',
        }
      ],

      detailPublic: {},
      detailClass: {},
      program: {},
      studies: {},
      competence: {},
      fasilitator: {},
      act: {},
      id_act: "",
      id_topic: "",
      information: {},
      listReview: {},

      more: 'Lihat Selengkapnya',
      less: 'Sembunyikan',
      dialog: false,
      totalData: null,

      //pagination review
      pageReview: 1,
      totalPage: 0,
      itemsPerPageArray: [10, 20, 50, 100, 200, 500],
      itemsPerPage: 20,

      itemsSortReview: [
        { text: 'Terbaru', value: 'desc' },
        { text: 'Terlama', value: 'asc' },
      ],
      sort_type: "desc",

      //inisialisasi rate
      filterRate: 0,
      rate5: null,
      rate4: null,
      rate3: null,
      rate2: null,
      rate1: null,
      persenRate1: 0,
      persenRate2: 0,
      persenRate3: 0,
      persenRate4: 0,
      persenRate5: 0
    }
  },
  mounted() {
    this.getDetailClass();
  },
  watch: {
    tab(val) {
      this.tabs = val;
    },
    filterRate: {
      handler: function () {
        this.getReviewClass(1);
        this.setReviewData(this.detailPublic.review);
      },
    },
  },
  computed: {
    dataReview() {
      return this.detailPublic?.review || {}
    },
  },
  methods: {
    convertDateReview(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    getPercen(value) {
      this.persenRate = parseInt(Math.round((value / this.rate.count) * 100));
      // console.log('test',this.persenRate)
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat('de-DE', {
      });
      return formatter.format(value);
    },
    dateTime(value) {
      var minut = value * 60;
      var hours = `${Math.floor(minut / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(minut / 60) % 60}`.padStart(2, 0);
      var seconds = `${minut % 60}`.padStart(2, 0);
      return `${hours}:${minutes}:${seconds}`;
    },
    change() {
      // console.log(showAll)
    },
    // getDetailClass() {
    //   this.axios
    //     .get(`/users/v1/public/program/detail/${this.$route.params.id}`)
    //     .then((response) => {
    //       this.content=true;
    //       this.loadContent=false;
    //       let res = response.data.data;
    //       this.detailClass = res;
    //       this.information = res.program_information.cover;
    //       // this.program = res.program_information;
    //       this.program = res.program_information;
    //       this.studies= res.learning_targets.studies;
    //       this.competence= res.learning_targets.competence;
    //       this.fasilitator= res.fasilitator;
    //     });
    // },
    async getDetailClass() {
      await this.axios
        .get(`/users/v1/public/program/detail/${this.$route.params.id}`)
        .then((response) => {
          this.content = true;
          this.loadContent = false;
          let res = response.data.data;
          this.detailClass = res;
          this.information = res.program_information.cover;
          // this.program = res.program_information;
          this.program = res.program_information;
          this.studies = res.learning_targets.studies;
          this.competence = res.learning_targets.competence;
          this.fasilitator = res.fasilitator;

          this.detailPublic = res;

          if (this.detailPublic.review) {
            this.setReviewData(this.detailPublic.review);
            if (this.detailPublic.review.total > 0) {
              this.getReviewClass(1);
            }
          }

          this.setReviewData(this.detailPublic.review);
        });
    },
    setReviewData(review) {
      this.persenRate5 = review.total > 0 ? (Math.round((review.star.five / review.total) * 100)) : 0;
      this.persenRate4 = review.total > 0 ? (Math.round((review.star.four / review.total) * 100)) : 0;
      this.persenRate3 = review.total > 0 ? (Math.round((review.star.three / review.total) * 100)) : 0;
      this.persenRate2 = review.total > 0 ? (Math.round((review.star.two / review.total) * 100)) : 0;
      this.persenRate1 = review.total > 0 ? (Math.round((review.star.one / review.total) * 100)) : 0;

      this.rate5 = review.star.five;
      this.rate4 = review.star.four;
      this.rate3 = review.star.three;
      this.rate2 = review.star.two;
      this.rate1 = review.star.one;
      this.rate = review;
    },
    toggleSort(sort_review) {
      this.sort_type = sort_review;
      this.getReviewClass(1);
    },
    changeItemsPerPageReview(value) {
      this.itemsPerPage = value;
      this.getReviewClass(1);
    },
    getReviewClass(page) {
      this.isLoad = true;
      this.isLoadList = true,
        this.axios
          .get(`/program_review/v1/list?`, {
            params: {
              program_id: this.$route.params.id,
              sort: "created_at",
              sort_type: this.sort_type,
              page: page,
              limit: this.itemsPerPage,
              is_publish: 1,
              star: this.filterRate
            },
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }
          })
          .then((response) => {
            this.isLoad = false;
            this.isLoadList = false,
              this.content = true;
            this.loadContent = false;
            let res = response.data.data;
            this.listReview = res.list;
            this.pageReview = res.page;
            this.itemsPerPage = res.limit;
            this.totalPage = Math.ceil(res.total_data / res.limit);
            this.totalData = res.total_data;
          });
    },
    // getReviewClass(value) {
    //   this.axios
    //     .get(`/program_review/v1/list?program_id=${this.$route.params.id}&sort=created_at&sort_type=desc&is_publish=1&limit=${value}`,
    //     {
    //         headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
    //       })
    //     .then((response) => {
    //       this.content=true;
    //       this.loadContent=false;
    //       let res = response.data.data;
    //       this.listReview = res.list;
    //       this.rate = res.rating;
    //       this.totalData=res.total_data;
    //       this.rate5=res.stars[0].star5;
    //       this.rate4=res.stars[0].star4;
    //       this.rate3=res.stars[0].star3;
    //       this.rate2=res.stars[0].star2;
    //       this.rate1=res.stars[0].star1;
    //         // console.log(this.rate5)
    //       this.persenRate5=parseInt( Math.round( ( this.rate5/this.rate.count ) * 100 ) );
    //       this.persenRate4=parseInt( Math.round( ( this.rate4/this.rate.count ) * 100 ) );
    //       this.persenRate3=parseInt( Math.round( ( this.rate3/this.rate.count ) * 100 ) );
    //       this.persenRate2=parseInt( Math.round( ( this.rate2/this.rate.count ) * 100 ) );
    //       this.persenRate1=parseInt( Math.round( ( this.rate1/this.rate.count ) * 100 ) );
    //     });
    // },
  },
};
</script>

<style>
.list-topik {
  max-height: 300px;
  overflow: auto;
}

#detail-kelas .v-list-group__items {
  background: #F9FAFC;
}

#detail-kelas .v-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

#detail-kelas .v-tab--active.v-tab:not(:focus):before {
  opacity: 0;
}

@media (max-width: 425px) {
  #detail-kelas .v-list-item {}
}
</style>